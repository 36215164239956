@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital@1&display=swap');

.logoContainer {
    padding: 25px;
    width: 230px;
}

.logo {
    font-family: 'Vollkorn', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 48px;
}
