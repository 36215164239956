.timer-circle {
    width: 300px;
    height: 300px;
    border: 2px solid #fff;
    border-radius: 50%;
}

.timer-hand {
    height: 125px;
    width: 2px;
    background-color: #fff;
    transform-origin: center bottom;
    position: relative;
    top: 25px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 1px;
    animation-name: timer;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes timer {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
 }