.backgroundBlur {
    position: absolute; /* AHHHHHHHHHHHHHHHHHHH */
    left: 0;
    top: 0;
    min-height: 100svh;
    width: 100svw;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
}

.content {
    width: 90vw;
    border: 2px solid #fff;
    padding: 20px;
    border-radius: 10px;
    background-color: #000000ee;
    backdrop-filter: blur(15px);
    box-shadow: rgba(159, 168, 177, 0.2) 0px 0px 25px 10px;
}

.form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.headField, .bodyField {
    display: grid;
    grid-template-columns: repeat(2, 100px);
    column-gap: 30px;
    row-gap: 15px;
}

.radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.img {
    max-width: 100px;
    border: 2px solid transparent;
    transition: 0.5s;
}

.h2 {
    border-bottom: 1px solid #fff;
    width: 230px;
}

input[type=radio]:checked + .img {
    transform: scale(1.3);
}

.bottomRow {
    display: flex;
    align-items: center;
    border-top: 1px solid #fff;
    padding-top: 20px;
    width: 230px;
}

.submitBtn {
    width: 65%;
}

.xBtnRow {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.xBtn {
    padding: 0;
    border: 0;
}