.content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.logoRow {
    display: flex;
    width: 100vw;
    justify-content: space-between;
}

.title {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    align-self: center;
}

.spacer {
    width: 230px;
}

.playerList {
    display: flex;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    align-content: flex-start;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 25px;
}

.playerStats {
    width: 280px;
    padding: 0 10px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
}

.icon {
    margin: auto;
}

.playerName {
    text-align: center;
    font-size: 24px;
}

.playerScore {
    font-size: 20px;
}

strong {
    font-weight: 600;
}

.endScoreGameCode {
    margin-right: 25px;
    margin-top: 25px;
}
