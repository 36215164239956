.welcome-animation {
    width: 400px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    mask-image: linear-gradient(to bottom, black calc(100% - 130px), transparent calc(100% - 50px));
    margin-bottom: -60px;
}

.large-box {
    width: 400px;
    height: 100px;
}

.large-box .quote, .small-box .quote {
    color: #fff;
}

.large-box .quote {
    font-size: 100px;
}

.large-box .open {
    margin-top: 10px;
}

.large-box .close {
    margin-top: 95px;
}

.small-box .quote {
    font-size: 60px;
    margin-top: 25px;
}

.small-box {
    height: 50px;
    width: 350px;
}

.small-box, .large-box {
    background-color: #666;
    /* background-color: #333; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
}
