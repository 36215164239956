@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    gap: 10px;
}

.prompt {
    max-width: 70%;
}

.logoRow {
    width: 100%;
    display: flex;
}

.answerRow {
    overflow: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
    .answerRow::-webkit-scrollbar {
        display: none;
    }

.answerList {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    height: 100%;
}

.prompt {
    width: 100%;
    height: auto;
    background-color: #22222299;
    backdrop-filter: blur(10px);
    box-shadow: rgba(159, 168, 177, 0.2) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: 2px solid #fff;
    z-index: 0;
    padding: 25px;
    margin-top: 25px;
    border-radius: 5px;
}

.prompt p {
    font-size: 24px;
}

.answer {
    border-radius: 5px;
    padding: 10px 25px;
    color: #fff;
    margin: 10px;
    height: auto;
    z-index: 99;
    background-color: #22222299;
    backdrop-filter: blur(10px);
    box-shadow: rgba(159, 168, 177, 0.2) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: 2px solid #fff;
    word-break: break-word;
}

.answerLg {
    width: calc((100% / 2) - 20px);
    font-size: 18px;
}

.answerMd {
    width: calc((100% / 3) - 20px);
    font-size: 16px;
}

.answerSm {
    width: calc((100% / 4) - 20px);
    font-size: 12px;
}

.answerText {
    margin: 0;
}

.answerBy {
    margin: 0;
    text-align: right;
    font-style: italic;
}

.gameCodeRow {
    margin: auto;
}

.awardImg {
    max-width: 50px;
    position: absolute;
    top: -20px;
    left: -20px;
}

.winner {
    background-color: #544d00;
    box-shadow: rgba(255, 217, 0, 0.2) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: 2px solid #FFD700;
}

.newestAnswer {
    z-index: 1000;
    position: absolute;
    margin: 20vh auto auto auto;
    width: 60%;
    background-color: #22222299 !important;
    transform: scale(0,0);
    animation: fadeOut 4s;
}

@keyframes fadeOut {
    0% {
        transform: scale(0,0);
    }

    15% {
        transform: scale(1,1);
    }

    85% {
        transform: scale(1,1);
    }

    100% {
        transform: scale(0,0);
    }
}