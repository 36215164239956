@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.quote {
    font-family: 'Playfair Display', serif;
    display: inline;
    color: #aaa;
}

@media (prefers-color-scheme: dark) {
    .quote {
        color: #aaa;
    }
}