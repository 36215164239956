@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.game-code-text {
    margin: 0 !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 64px;

}

.game-code {
    width: 200px;
    height: 90px;
    border: 3px solid #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
