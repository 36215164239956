.waitAnimation {
    animation-name: wait;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes wait {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1) rotate(5deg);
    }
    100% {
      transform: scale(1);
    }
 }