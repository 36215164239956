@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
}

.logoRow {
    width: 100vw;
    display: flex;
    justify-content: space-between;
}

.playerList {
    display: flex;
    flex-wrap: wrap;
    max-width: 90vw;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
}

.gameCodePlayerList {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.player {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconWidth {
    width: 150px;
}

.playerNameSmall {
    font-size: 32px;
}

.playerNameMed {
    font-size: 42px;
}

.gameCode {
    margin-top: 25px;
    margin-right: 25px;
}

.linkText {
    padding: 24px;
    font-size: 36px;
    text-align: center;
    animation: emphasize 4s infinite;
    transform: rotate(-5deg)
}

.qmotLink {
    color: gold;
}

@keyframes emphasize {
    0% {
        padding: 24px;
        font-size: 36px;
    }

    50% {
        padding: 4px;
        font-size: 42px;
    }

    100% {
        padding: 24px;
        font-size: 36px;
    }
}