.content {
    min-height: 100svh;
    width: 100svw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
}

.logoRow, .promptAnswer {
    width: 100vw;
}

.textareaContainer {
    display: flex;
    width: 100vw;
    justify-content: center;
    margin-top: 25px;
}

.quote {
    font-size: 64px;
}

.close {
    align-self: flex-end;
    margin-bottom: -36px;
}

.prompt {
    width: 80vw;
    border: 2px solid #fff;
    padding: 6px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    gap: 25px;
}

.textarea {
    resize: none;
    width: 75vw;
    padding: 6px;
    border-radius: 5px;
    background-color: #ffffff10;
    border: 2px solid #fff;
    color: #fff;
    font-size: 1.65rem;
}

.textarea::placeholder {
    color: #ddd;
}

.textarea::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.textarea:focus {
    outline: none;
}

.textareaSpan {
    display: flex;
    flex-direction: column;
}

.textareaSpan span {
    margin-left: auto;
}

.btn {
    width: 75vw;
}