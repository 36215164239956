.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    min-height: 100svh;
    width: 100svw;
    padding-bottom: 10px;
}

.quote {
    font-size: 16px;
}

.prompt {
    width: 90%;
    height: auto;
    font-size: 18px;
}

.prompt p {
    font-weight: 500;
}

.title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}

.response {
    width: 80vw;
    border-radius: 10px;
    font-size: 16px;
    border: 2px solid #fff;
    background-color: #22222299;
    backdrop-filter: blur(10px);
    padding: 10px;
    display: flex;
    cursor: default;
    flex-direction: column;
    word-break: break-word;
    transition: 200ms ease-out;
}

.quoteBy {
    font-size: 1rem;
    align-self: flex-end;
}

.responsesContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.response:last-of-type {
    margin-bottom: 100px;
}

.gameCodeRow {
    margin-left: auto;
    margin-right: 10px;
}

.submitBtn {
    width: 75%;
    padding: 5px;
    position: fixed;
    top: calc(100svh - 10px);
    left: 50vw;
    transform: translate(-50%, -100%);
    background-color: #00000044;
    backdrop-filter: blur(15px);
    transition: 400ms ease-out;
}

.br {
    height: 1px;
    width: 90%;
    background-color: #999;
    color: #999;
}

.selectedResponse {
    border: 2px solid #FFD700;
    background-color: #544d00;
}