@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.content {
    max-width: 900px;
    min-width: 300px;
    height: auto;
    padding: 20px;
}

.quoteText {
    font-size: 36px;
    margin-right: 100px;
}

.quoteText, .quoteBy {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

.quote {
    font-size: 36px;
}

.quoteBy {
    text-align: end;
    margin-right: 15px;
}