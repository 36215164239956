.content {
    min-height: 100svh;
    width: 100vw;
    align-items: center;
}

.content,
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo {
    font-size: 64px;
}

.onThat {
    margin-left: 30px;
}

form {
    width: 70%;
    align-items: flex-start;
}

.label {
    font-size: 24px;
}

.nameLabel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.codeLabel {
    margin-top: 25px;
}

.input {
    width: 100%;
    border: 2px solid #fff;
    height: 48px;
    padding: 6px;
    font-size: 24px;
    border-radius: 5px;
    background-color: #ffffff10;
    color: #fff;
}

input:focus {
    outline: none;
}

.error {
    border-color: red;
    color: red;
    background-color: #fce4e4;
}

.errorLabel {
    color: red;
}

.submitBtn {
    margin-top: 25px;
    width: 100%;
}
