.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100svh;
    width: 100svw;
}

.welcomePlayer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.urine {
    font-size: 48px;
    font-weight: 400;
}

.playerName {
    font-size: 36px;
}

.fakeIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.fakeHead {
    background-color: #fff;
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.fakeBody {
    background-color: #fff;
    height: 25px;
    width: 55px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.btns {
    width: 100vw;
    display: flex;
    padding: 10px;
    gap: 10px;
}

.customizeBtn,
.startGameBtn {
    width: calc(50vw - 15px);
}

.customizeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 5px;
}
