@import url("https://fonts.googleapis.com/css2?family=Vollkorn:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

#container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 50px;
    width: 100vw;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

h1 {
    font-family: "Vollkorn", serif;
    font-weight: 400;
    font-style: italic;
    font-size: 100px;
    margin-right: auto;
}

.on-that {
    margin-left: 40px;
}

h1:hover {
    cursor: default;
}

.welcome-button {
    width: 400px;
    position: absolute;
    top: calc(50vh - 40px);
    left: calc(50vw - 200px);
    transition: 0.3s;
}

.welcome-button:hover {
    transform: scale(1.03);
}
