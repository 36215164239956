.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100svh;
    width: 100svw;
    padding-bottom: 10px;
}

.animationContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.animationContainer h2 {
    font-size: 2rem;
    text-align: center;
}

.animation {
    filter: invert(100%);
    width: 200px;
}

.gameCodeRow {
    margin-left: auto;
    margin-right: 10px;
}