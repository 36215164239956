.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100svh;
    width: 100svw;
    padding-bottom: 10px;
}

.gameOverContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: center;
    align-items: center;
}

.gameOver {
    font-size: 3rem;
}

.btn {
    width: 80vw;
}

.br {
    width: 90vw;
    height: 1px;
    background-color: #666;
}

.gameCodeRow {
    margin-left: auto;
    margin-right: 10px;
}