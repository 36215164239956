.content {
    display: flex;
    justify-content: space-between;
}

.timerPlayers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding-top: 25px;
    max-width: 800px;
}

.playerList {
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    column-gap: 50px;
}

.player {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playerName {
    font-size: 64px;
}

.gameCodeCol {
    height: 100vh;
    display: flex;
    align-items: flex-start;
    padding-top: 25px;
    padding-right: 25px;
}
